const ALL_CONTENT = {
  // Menu Section
  HOME: {
    title: "Home",
    nav: {
      href: "#home",
      badge: "home"
    }
  },
  UPCOMING: {
    title: "Upcoming Events",
    nav: {
      href: "https://www.facebook.com/groups/clothnappyworkshopsmelbourne/events",
      hrefMobile: "https://m.facebook.com/groups/clothnappyworkshopsmelbourne?view=events",
      target: "_blank",
      rel: "noopener",
      badge: ["far", "calendar-alt"],
      showLabel: true
    }
  },
  FACEBOOK: {
    title: "Like us on Facebook",
    nav: {
      href: "https://facebook.com/groups/clothnappyworkshopsmelbourne",
      target: "_blank",
      rel: "noopener",
      content: "Facebook Group",
      badge: ["fab", "facebook-square"]
    }
  },
  INSTAGRAM: {
    title: "Follow us on Instagram",
    nav: {
      href: "https://instagram.com/clothnappyworkshopsmelbourne",
      target: "_blank",
      rel: "noopener",
      content: "Instagram Roll",
      badge: ["fab", "instagram"]
    }
  },
  YOUTUBE: {
    title: "Subscribe to our YouTube channel",
    nav: {
      href: "https://youtube.com/channel/UC3O3Wi5LBU7IxoRkPTiRiIQ",
      //href: "https://youtube.com/clothnappyworkshopsmelbourne",
      target: "_blank",
      rel: "noopener",
      content: "YouTube Channel",
      badge: ["fab", "youtube"]
    }
  },
  THEME: {
    title: "Toggle Light/Dark mode",
    nav: {
      onClick: "toggle",
      href: "#home",
      //href: "https://youtube.com/clothnappyworkshopsmelbourne",
      content: "Switch Modes",
      badge: {
        light: ["fas", "moon"],
        dark: ["fas", "sun"]
      }
    }
  },

  // Profile Section
  PROFILE: {
    title: "About me",
    content: "Elana lives a minimalist and low waste lifestyle, with two young children who spent a combined 5 years in cloth nappies. She has a degree in Social Science (Environment) and is passionate about reducing the environmental impact of households, but also understands the pressures of modern life and balancing work and children. Elana has spent the last few years running workshops, having presented at a number of Council events and festivals.",
    welcome: "I acknowledge the Wurundjeri Woi Wurrung as the traditional custodians of the land where I live and work, and pay my respects to their Elders past, present and emerging.",
    video: {
      ytEmbedId: "nlKbIRLan6U"
    },
    nav: {
      content: "Profile",
      href: "#profile",
      badge: ["far", "id-badge"]
    }
  },

  // Workshops Section
  WORKSHOPS: {
    title: "Workshop topics",
    content: "Below you can find information on the types of workshops provided, and the topics they cover. To register for an upcoming workshop either in your area or virtually via the online platform Zoom, check out the Events page in our Facebook community group.",
    group: {
      href: "https://www.facebook.com/groups/clothnappyworkshopsmelbourne",
      hrefMobile: "https://m.facebook.com/groups/clothnappyworkshopsmelbourne"
    },
    nav: {
      content: "Workshops",
      href: "#workshops",
      badge: "list-ul"
    }
  },
  REUSABLES: {
    title: "All Things Reusable",
    content: "This workshop is all about the reusable alternatives to everyday household items; perfect for those starting out on their zero‐waste journey. The presentation covers reusable substitutes for items such as paper towel, tissues, make‐up removal, plastic wrap, face wipes, and shopping and produce bags. Cloth nappies and menstrual products are also covered but aren’t discussed at length, as compared to the dedicated sessions below.",
    //href: "https://www.trybooking.com/BJFDR"
    button: {
      content: "Book a ticket",
      // ebEventId: "158208639299"
    },
  },
  NAPPIES: {
    title: "Cloth Nappies 101",
    content: "A comprehensive introduction to cloth nappies which suits both expectant parents, and those who already have children in nappies. The presentation covers the benefits of cloth nappies (environmental, economical, etc.), the different styles (fitted, pockets, AI2s, OSFM, etc.), how to wash them, and simple tips and tricks to make cloth super easy. With over 60 nappies and accessories, you can not only ask questions and be shown cloth nappies, but also other reusables such as pull-ups, swim nappies and cloth wipes.",
    //href: "https://www.trybooking.com/BJGMB"
    button: {
      content: "Book a ticket",
      ebEventId: "204385952887",
      expiry: "2021/11/17" // YYYY/MM/DD, will show inclusive
      // https://www.eventbrite.com.au/e/cloth-nappies-101-virtual-workshop-tickets-204385952887
    },
  },
  MENSTRUALS: {
    title: "Reusable Menstrual Products",
    content: "For those who want waste free periods but are unsure how to take the next step. The presentation comprehensively covers washable cloth pads, menstrual discs and cups, and absorbent period underwear, how to wash and care for them, and which reusable product may suit your needs.",
    //href: "https://www.trybooking.com/BKORI"
    button: {
      content: "Book a ticket",
      // ebEventId: "158208639299"
    },
  },
  // GIFTS: {
  //   title: "Gift Ideas",
  //   content: "Struggling for a gift? You can purchase a workshop voucher for your friend or loved one (baby showers are usually popular). Simply send me a message to make arrangements, or get one using the button below.",
  //   button: {
  //     href: "https://www.trybooking.com/book/giftCertificates?aid=111128",
  //     content: "Purchase a Gift Certificate"
  //   }
  // },
  OTHER: {
    title: "More...",
    content: "In addition to the above community workshops, I also run custom presentations for Council MCHN staff, hospital antenatal educators, health care providers, child care centres and other organisations who may be looking to up-skill staff or establish a cloth nappy initiative. If you'd like to talk through an idea, please use the contact form below to get in touch or feel free to reach out to me on Facebook."
  },

  // Gallery Section
  GALLERY: {
    title: "Photo gallery",
    content: "Below you can find images of where I've appeared for both festivals and workshops, along with some examples of nappy stands I helped create for use in the Mercy Hospital and various Maternal & Child Health centres.",
    nav: {
      content: "Gallery",
      href: "#gallery",
      badge: "photo-video"
    }
  },

  // Testimonials Section
  PARTNERS: {
    title: "Partner organisations",
    content: "Below you can find a list of all the organisations I've worked with in the past, including Councils, festivals and health services.",
    nav: {
      content: "Partners",
      href: "#partners",
      badge: "university"
    }
  },
  COUNCILS: {
    title: "Councils",
    content: "It has been encouraging to see Local Government Councils leading the way by introducing initiatives and cloth nappy education, to help support families within their community in reducing waste going to landfill. Below are the Council's and organisations I've partnered with to deliver educational workshops.",
  },
  FESTIVALS: {
    title: "Festivals",
    content: "I've appeared at many festivals in the past to promote cloth nappies, display the different reusable nappy options and answer questions from the community. Below are some of the events you may have seen me at.",
  },
  HEALTH: {
    title: "Health Services",
    p1: {
      content: [
        "I was honoured to have assisted the Antenatal Education team at the Mercy Hospital for Women with updating the cloth nappy information provided to expectant parents.",
        "I also ran a custom modern reusable nappies presentation for the Antenatal Educators to support them in providing up to date and current information to expectant parents."
      ]
    },
    p2: {
      content: [
        "Additionally I am member of the Continence Foundation of Australia, an organisation which is part of an Australian government initiative focused on continence health to provide awareness, advocacy, education and conduct research."
      ]
    }
  },

  // Media Section
  MEDIA: {
    title: "In the media",
    content: "Below you can find a list of places where I've appeared in the news and on radio.",
    nav: {
      content: "Media",
      href: "#media",
      badge: "video"
    }
  },
  BANYULE: {
    title: "September 9, 2021 - Banyule City Council",
    content: [
      "I was proud to partner with",
      "on providing nappy education as part of their custom reusable nappy launch. You can"
    ],
    links: [{
      value: "Banyule City Council",
      href: "https://www.banyule.vic.gov.au/"
    },{
      value: "read the full article on the Banyule City Council website.",
      href: "https://www.banyule.vic.gov.au/News-items/Banyule-reusable-nappy/"
    }]
  },
  STAR: {
    title: "August 3, 2021 - Star Weekly",
    content: [
      "I was interviewed by",
      "as part of a piece on the journey to zero waste. In this article I share why I'm passionate about living a low waste lifestyle, and the different workshops I present. This article coincided with Plastic Free July 2021 where I was fortunate to present a number of Council webinars, and shared tips on how to start a low waste journey. You can"
    ],
    links: [
      {
        value: "Sunbury & Macedon Ranges Star Weekly",
        href: "https://sunburymacedonranges.starweekly.com.au/"
      },
      {
        value: "read the full article on the Star Weekly back issues website.",
        href: "https://issuu.com/starweekly/docs/2021-08-03_ssw_739/8"
      }
    ]
  },
  PVFM: {
    title: "May 5, 2021 - Plenty Valley FM",
    content: [
      "I was interviewed by",
      "to talk about the benefits of reusable nappies, Banyule City Council's cloth nappy workshop program, and how choosing reusable nappies can help reduce the amount of disposable nappies in landfill. If you would like to listen to the snippet you can use the audio controls below. You can",
      "Alternatively, you can"
    ],
    links: [
      {
        value: "Plenty Valley FM",
        href: "https://www.pvfm.org.au"
      },
      {
        value: "listen to the full segment on the Plenty Valley FM podcast website.",
        href: "https://omny.fm/shows/pvfm-news/pvfm-news-hour-podcast-2021-5-5"
      },
      {
        value: "read the transcript.",
        href: "modal"
      }
    ],
    audio: "interview-pvfm-192.mp3",
    modal: {
      title: "Plenty Valley FM - Transcript",
      table: [
        [ "0:06", "Denise", "And well there's loads of workshops and things to do around Banyule, and here's one story that also has an environmentally friendly ring to it. Bizarre but true, reusable nappies, well, is there a better way? Well, I think so. Because Elana lives a minimalist and low waste lifestyle. She's got a degree in social science, environment and is passionate about reducing the environmental impact of households. With two young children who spent a combined five years in cloth nappies, Elana has spent the last few years running workshops in the local area on how to use reusable nappies. You're listening to your local station at 88.6 Plenty Valley FM, you're on the Plenty Valley FM news hour. First of all, what kinds of things would you be teaching the public about disposable nappies?" ],
        [ "1:01", "Elana", "Well, I do explain that one child goes through around 6000 disposable nappies before they toilet train. So I mention reusable nappies as an option just to avoid some of that landfill waste generated by children." ],
        [ "1:15", "Denise", "What are the benefits to the community regarding using reusable nappies?" ],
        [ "1:20", "Elana", "So many, I think the main one at the moment is just that money saving aspect. So instead of using a disposable nappy for a few hours and throwing it away, you can use a reusable nappy for multiple years and multiple children and not having to spend too much money, you know, using nappies on your baby. There's a benefit to council because soon, very soon in the next few years, they're going to be charged more to divert waste to landfill. So the money Council saves by not having so many nappies in the bins, they can use those funds to support other council initiatives and council projects. And the other benefit to families using cloth nappies is just they're so much easier to use than they used to be, and they're a lot more fun and cute, and some parents actually match their nappies to their child's outfit and have a lot of fun with it." ],
        [ "2:06", "Denise", "In the past, before even disposable nappies were around, there were cloth nappies, and oh yeah. I went through two children with cloth nappies, and it's disgusting. Absolutely disgusting to do that. But nevertheless, when you brought in these reusable nappies, are they based on the same sort of cloth fabric, and how long does that cloth fabric last? In other words, how are the nappies constructed?" ],
        [ "2:36", "Elana", "The modern reusable nappies have changed a lot since the folded fabric square that used to wrap around a baby and fasten with a pin. You don't need to do that anymore. So the modern nappies are shaped like a disposable. They have modern fabric, bamboo, hemp, which is a lot more absorbent than their traditional counterparts. They've got covers that are very stretchy, breathable, and wrap around baby really comfortably. So yeah, they've changed a lot in terms of their absorbency and their function and their fit. But more importantly, they're easier to wash now. So you don't need to soak them. You don't need to use Napisan, you just give them a quick rinse and your machine does the rest. You know, 10 minutes twice a week just to prep the nappies before you wash them is all you need to do. And the money we saved by using cloth nappies on our children, we paid a house cleaner to clean our house for a few hours. So it was saving us a little bit of time, but also just making life all round more convenient." ],
        [ "3:34", "Denise", "Good for the budget, good for time." ],
        [ "3:37", "Elana", "Yes, good for time and good for the environment." ],
        [ "3:39", "Denise", "Ultimately, how long will these reusable nappies last?" ],
        [ "3:43", "Elana", "They last definitely one child through to toilet training. So that's around three years. The cloth nappies that we used and some of the more premium brands will easily last two children, but the ones we had on our children actually went through three children. So the 30 cloth nappies we purchased were used on three children, diverting 18,000 disposable nappies from landfill. So 30 nappies instead of 18,000 disposables, and those nappies I've got one here today looks brand new still, after three children and seven and a half years of use. If they're washed well, they're looked after then yeah, there's no reason why they can't keep being used on subsequent children. And that's the extra benefit of cloth nappies is that the more children they're used on, the the greater the environmental benefit." ],
        [ "4:33", "Denise", "Now, where are these workshops taking place?" ],
        [ "4:35", "Elana", "Due to COVID we went from in-person workshops to virtual so that was quite common last year, and we're continuing that so they're on Zoom. They run every month through Banyule Council, and parents who live in the area can sign up. It's $20 per household, but in return they get to attend the workshop and council provides two free premium cloth nappies For use again and again after the workshop. The nappies, if you were to build a stash of cloth nappies, you need around 30 and they're about $20 to $35 each depending on which brand you go with. But you still save around $2,000 by going into cloth nappies compared to disposable so that $2,000 you save yeah, it can be put to the mortgage can be put towards a house cleaner can be... " ],
        [ "5:22", "Denise", "Where we can save, that's the best way. Okay, with everything I suppose, in the end, how do we dispose of the reusable nappy?" ],
        [ "5:33", "Elana", "Sure. So like anything when it's done its job and it's ready to retire, most things do end up in landfill eventually. My nappies, after three children's worth of use and saving 18,000 going into landfill, 30 going into landfill's not a bad trade off. If you do choose cloth nappies that don't have any plastic components to them, and there are options available, then they can be composted. So, there are some cloth nappies that can be composted after they're retired, when they retired. But for mine, if I don't repair the elastics, then they may end up in landfill or I could pass them on to a, there's an organization called the cloth community fund. They repair second-hand cloth nappies and pass them on to families who really need them." ],
        [ "6:16", "Denise", "You've mentioned where you do book, with the Banyule Council?" ],
        [ "6:20", "Elana", "Yes, so through council's website. I've also got a Facebook group Cloth Nappy Workshops Melbourne. I list all upcoming cloth nappy workshops there and I do some other virtual workshops on different topics, different reusable product topics. So yeah, people can check out upcoming workshop events there." ],
        [ "6:38", "Denise", "Thank you very much for coming onto the program." ],
        [ "6:40", "Elana", "Thank you for having me." ]
      ]
    }
  },
  THEAGE: {
    title: "March 16, 2020 - The Age",
    content: [
      "I was interviewed by",
      "at the beginning of the COVID-19 pandemic when disposable nappies were in short supply, to help parents find reusable alternatives to nappies, wipes and other household items most affected by panic buying. You can"
    ],
    links: [
      {
        value: "The Age",
        href: "https://www.theage.com.au/"
      },
      {
        value: "read the full article on The Age website.",
        href: "https://www.theage.com.au/national/victoria/kindness-outbreak-keeping-people-occupied-and-entertained-20200316-p54and.html"
      }
    ]
  },
  BESTPEGS: {
    title: "January 21, 2020 - Best Pegs",
    content: [
      "I was interviewed by",
      "to talk about how we can reduce our landfill, while going through some of the key numbers and persistant myths. You can"
    ],
    links: [
      {
        value: "Best Pegs",
        href: "https://bestpegs.com.au/"
      },
      {
        value: "read the full article on the Best Pegs website.",
        href: "https://bestpegs.com.au/cloth-nappy-workshop-interview/"
      }
    ]
  },

  // Research Section
  RESEARCH: {
    title: "Research",
    content: "Below you can find a list of studies conducted regarding the education and use of reusable products in local communities.",
    nav: {
      content: "Research",
      href: "#research",
      badge: ["fas", "book-open-reader"],
      special: true
    }
  },
  STUDY_INCONTINENCE: {
    title: "June 2023 - Reusable Sanitary and Incontinence Aid Study",
    p1: {
      content: [
        "In Australia, approximately 660 million disposable nappies, sanitary and incontinence products go to landfill each year, accounting for between 5 to 15 per cent of all household waste sent to landfill.",
        "Maroondah City Council alongside six other councils undertook a feasibility study into local government reusable sanitary and incontinence programs.",
      ]
    },
    p2: {
      content: [
        "The target of the study was to better understand how councils could can help reduce the volume of disposable sanitary and incontinence products sent to landfill, as well as encouraging cost effective and environmentally friendly sanitary and incontinence product choices."
      ]
    },
    p3: {
      content: [
        "Participants of the survey were invited to share their experience with sanitary and/or incontinence products by taking part in a survey.",
        "The aim was to identify what type of sanitary/incontinence product people used, awareness of reusable alternatives, and general openness to know more about them."
      ]
    },
    p4: {
      content: [
        "This project was supported by the Recycling Victoria Councils Fund and delivered by Sustainability Victoria, on behalf of the Victorian Government."
      ]
    }
  },
  STUDY_NAPPIES: {
    title: "October 2022 - Reusable Nappy Feasibility Study",
    p1: {
      content: [
        "Monash Council led a",
        "with 12 other Melbourne Councils to research and understand how to implement a best practice reusable nappy program.",
      ],
      links: [
        {
          value: "Reusable Nappy Feasibility Study",
          href: "https://shape.monash.vic.gov.au/reusable-nappies"
        }
      ]
    },
    p2: {
      content: [
        "To support ongoing use and uptake, the study recommended Councils deliver more education and more opportunities for expectant families and the community to experience and connect with reusable nappy programs and products.",
        "Removing cost as a barrier was not a prominent reason for continued use of reusable nappies amongst current users."
      ]
    },
    p3: {
      content: [
        "Based on the research, a best practice reusable nappy program should provide opportunities for expectant and current parents and carers to \"experience\" reusable nappies before they invest, delivered in tandem with the Communications Strategy and Campaign."
      ]
    },
    p4: {
      content: [
        "This includes visual and physical experiences to understand the types of reusable nappies and related products available. The social research showed families may be reticent to invest in a product they have not seen in person, and are particularly interested in firsthand experiences to understand size, style and absorbency."
      ]
    },
    p5: {
      content: [
        "(Page 57 - Reusable Nappy Feasibility Study Final Report)"
      ]
    },
    p6: {
      content: [
        "The 5-month study included a review of 9 Melbourne-based, 4 Australia-wide and 3 international reusable nappy programs, and 12 reusable nappy brands - including a triple-bottom line assessment (economic, enviromental and social benefits).",
        "Social research was also undertaken with 2,282 expectant parents and current parents and carers across Melbourne.",
        "The research found that as many as 90% of expectant parents and carers were open to assistance to help make the switch to reusables through interventions like workshops, trial kits or advice from a trusted source."
      ]
    },
    p7: {
      content: [
        "The Reusable Nappy Feasibility Study proposes two key recommendations for consideration and potential implementation:"
      ]
    },
    p8: {
      content: [
        "Some of the ways identified for reaching new and expectant parents included:"
      ]
    }
  },

  // Contact Section
  CONTACT: {
    title: "Get in touch",
    content: "If you're looking for more information try joining our Facebook group, following us on Instagram, subscribing to our YouTube channel, or sending us an email using the contact form below.",
    nav: {
      content: "Contact",
      href: "#contact",
      badge: ["far", "envelope-open"]
    }
  }
};

export default ALL_CONTENT;
